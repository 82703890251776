<template>
  <main id="charge">
    <section class="section-top"></section>
    <ConnectorInfoCard />
    <section class="section-main">
      <div class="section-main__content stopping">
        <inline-svg :src="require('../../assets/icon/svg/error-hint.svg')" />
        <div class="section-main__title">
          <h1 class="fail">停止充電失敗</h1>
        </div>
        <p>請回到充電進度重新連線，或稍候再試。</p>
        <p>
          聯絡客服人員：0809-0809-81 / LINE：<a
            href="https://line.me/R/ti/p/@u-powertw?from=page"
            target="_blank"
            >@u-powertw</a
          >
        </p>
      </div>
      <div class="section-main__action">
        <button
          class="button is-fullwidth is-fill is-rounded"
          @click="handleClick()"
          type="button"
        >
          回到充電進度
        </button>
      </div>
    </section>
  </main>
</template>
<script>
import ConnectorInfoCard from "../Connector/ConnectorInfoCard.vue";
export default {
  name: "StopErrorMessage",
  components: { ConnectorInfoCard },
  methods: {
    handleClick() {
      this.$router.push({
        name: "Charging-progress",
        params: {
          chargingId: this.$route.params.chargingId,
          connectorId: this.$route.params.connectorId,
          stationId: this.$route.params.stationId,
        },
      });
    },
  },
};
</script>
