<template>
  <section class="section-info">
    <div class="section-info-content">
      <div class="info-title">
        <h1>
          充電槍：{{ connector.connector }} ({{
            connector.connectorType.connectorType
          }})
        </h1>
      </div>
      <div class="info-location">
        <p>您目前所在的位置：{{ station.station }}</p>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ConnectorInfoCard",
  async created() {
    const { connectorId, stationId } = this.$route.params;
    this.$store.dispatch("fetchConnector", {
      connectorid: connectorId,
    });
    this.$store.dispatch("fetchStation", {
      stationid: stationId,
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const { connectorId, stationId } = to.params;
    this.$store.dispatch("fetchConnector", {
      connectorid: connectorId,
    });
    this.$store.dispatch("fetchStation", {
      stationid: stationId,
    });
    next();
  },
  computed: {
    ...mapState(["connector", "station"]),
  },
};
</script>
